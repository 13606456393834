import React, { useEffect } from "react"
import { Routes } from "../../data/routes"
import CTA from "../../components/CTA"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const Content = {
  pageName: "Download the app",
  header: "Redirecting you to app...",
  description: "Redirecting you to app...",
  webRedirect: "https://student.yup.com",
  iosRedirect: "https://bnc.lt/ZQnd/pplvePNHFE",
  androidRedirect: "https://bnc.lt/ZQnd/pplvePNHFE",
  actions: [
    {
      link: Routes.home,
      text: "Go home",
    },
  ],
}

const App = () => {
  useEffect(() => {
    if (typeof window === "undefined") return

    // Detection based on https://stackoverflow.com/questions/21741841
    const userAgent = navigator.userAgent || navigator.vendor || window.opera

    if (/android/i.test(userAgent)) {
      window.location.assign(Content.androidRedirect)
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.assign(Content.iosRedirect)
    } else {
      window.location.assign(Content.webRedirect)
    }
  }, [])

  return (
    <Layout>
      <Seo
        title={Content.pageName}
        description={Content.description}
        route={Routes.app}
      />
      <CTA title={Content.header} actions={Content.actions} />
    </Layout>
  )
}

export default App
